"use client";
import { useSession } from "next-auth/react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { saveUserCurrentCountry, saveUserCurrency, getUserCurrentCountry } from "@/lib/features/home/homeSlice";
import { currencies } from "@/data/currencies";

const SessionUpdater = () => {
  const { data: session, status } = useSession();
  const dispatch = useAppDispatch();
  const userCurrentCountry = useAppSelector(getUserCurrentCountry);

  useEffect(() => {
    if (status === "authenticated" && session?.user) {
      const currentCountryCode = session.user?.country_code;
        console.log(" session.user:",  session.user);
      if (currentCountryCode !== userCurrentCountry) {
        console.log("Country code changed, updating Redux:", currentCountryCode);
        const currencyData: any =
          currentCountryCode &&
          currencies?.filter(
            (item) => item.nationality_code === currentCountryCode
          );
        const userCurrency =
          currencyData?.length > 0 ? currencyData[0] : currencies[0];
        const userCountry = currentCountryCode || currencies[0]?.nationality_code;

        dispatch(saveUserCurrentCountry(userCountry));
        dispatch(saveUserCurrency(userCurrency));
      }
    } else if (status === "unauthenticated") {
      console.log("Unauthenticated, resetting state");
      // Optionally reset homeSlice
      // dispatch(saveUserCurrentCountry(null));
      // dispatch(saveUserCurrency(currencies[0]));
    }
  }, [status, session, dispatch, userCurrentCountry]);

  return null;
};

export default SessionUpdater;