import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Jost\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"display\":\"swap\",\"preload\":true,\"variable\":\"--font-jost\"}],\"variableName\":\"jost\"}");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/swiper/modules/pagination.css");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/swiper/modules/scrollbar.css");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/swiper/modules/effect-cards.css");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/public/img/general/logo-light.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/src/app/bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/src/app/MapsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/veels-frontend/src/app/SessionProviders.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/src/app/SessionUpdater.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/src/app/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/src/components/common/ScrollTop.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/src/styles/index.scss");
