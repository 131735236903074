// A mock function to mimic making an async request for data
export const fetchCount = async () => {
    const response = await fetch("https://devcms.envoyhealth.io/api/v1/web/blogs", {
      method: "GET",
      // headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({ amount }),
    });
    const result: { data: any } = await response.json();
  
    return result;
  };

  export const fetchPlaces = async () => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/v1/places?q=luxor&page=1`, {
      method: "GET",
      // headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({ amount }),
    });
    const result: { data: any } = await response.json();
  
    return result;
  };