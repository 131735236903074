import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  tabs: [
    { id: 1, name: "Hotel", icon: "icon-bed" },
    { id: 2, name: "Tour", icon: "icon-destination" },
    { id: 3, name: "Activity", icon: "icon-ski" },
    { id: 4, name: "Holyday Rentals", icon: "icon-home" },
    { id: 5, name: "Car", icon: "icon-car" },
    { id: 6, name: "Cruise", icon: "icon-yatch" },
    { id: 7, name: "Flights", icon: "icon-tickets" },
  ],
  currentTab: "Hotel",
  data: [],
  status: 'idle',
  error: null
};


export const fetchData = createAsyncThunk('example/fetchData', async () => {
  const response = await axios.get('https://jsonplaceholder.typicode.com/posts');
  return response.data;
});

export const findPlaceSlice = createSlice({
  name: "hero",
  initialState,
  reducers: {
    addCurrentTab: (state, { payload }) => {
      state.currentTab = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state: any) => {
        state.status = 'loading';
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed';
        // state.error = action.error.message || 'Something went wrong';
      });
  },
});

export const { addCurrentTab } = findPlaceSlice.actions;
export default findPlaceSlice.reducer;
