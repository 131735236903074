import { createAppSlice } from "@/lib/createAppSlice";
import { getFetchApi, deleteApi } from "@/utils/http";
import { getMarkupPrice } from "@/utils/helper";
import { showToast } from "@/app/CustomToastProvider";

export interface BookingSliceState {
  bookingLoading: boolean;
  viewBookingStatusLoading: boolean;
  cancelBookingLoading: boolean;
  viewBookingLoading: boolean;
  bookings: any;
  viewBooking: { [key: number | string]: any };
  viewBookingStatus: { [key: number | string]: any };
  paginationObject: any;
  error: string | null;
}

const initialState: BookingSliceState = {
  bookingLoading: false,
  viewBookingStatusLoading: false,
  cancelBookingLoading: false,
  viewBookingLoading: false,
  bookings: [],
  viewBooking: {},
  viewBookingStatus: {},
  paginationObject: {},
  error: null,
};

export const bookingSlice = createAppSlice({
  name: "booking",
  initialState,
  reducers: (create) => ({
    getMyBookings: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await getFetchApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.bookingLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, meta }) => {
          const { data, ...rest } = payload?.data;
          state.cancelBookingLoading = false;
          state.bookingLoading = false;
        
          const updateState = data?.map((_: any) => {
            const mark_up_percentage = _?.mark_up_percentage;
            const booking_details = _?.booking_details;
            const hotel = booking_details?.hotel;
          
            const updatedData = {
              ..._,
              booking_details: {
                ...booking_details,
                price: {
                  ...booking_details?.price,
                  price_markup: getMarkupPrice(booking_details?.price?.total, mark_up_percentage),
                },
                hotel: {
                  ...hotel,
                  booking_items: hotel?.booking_items?.map((item: any) => {
                    const updatedCancellationPolicy = {
                      ...item?.cancellation_policy,
                      // Add price_markup in details array
                      details: item?.cancellation_policy?.details?.map((detail: any) => {
                        if (item?.cancellation_policy?.amount_type === "value") {
                          const detailMarkup = getMarkupPrice(detail?.nights, mark_up_percentage); // 10% markup on nights
                          return {
                            ...detail,
                            price_markup: detailMarkup, // Add price_markup inside each detail
                          };
                        }
                        return detail; // Keep details unchanged if amount_type is not 'nights'
                      }),
                      // Add price_markup in no_show_fee
                      no_show_fee: item?.cancellation_policy?.no_show_fee?.amount_type === "value"
                        ? {
                            ...item?.cancellation_policy?.no_show_fee,
                            price_markup: getMarkupPrice(item?.cancellation_policy?.no_show_fee?.nights, mark_up_percentage), // 10% markup on no_show_fee nights
                          }
                        : item?.cancellation_policy?.no_show_fee, // Keep no_show_fee unchanged if amount_type is not 'nights'
                    };
          
                    return {
                      ...item,
                      price_markup: getMarkupPrice(item?.price, mark_up_percentage),
                      cancellation_policy: updatedCancellationPolicy, // Updated cancellation policy
                    };
                  }),
                },
              },
            };
            return updatedData;
          });
          
         
          state.bookings = updateState || [];

          state.paginationObject = rest;
        },
        rejected: (state, action) => {
          state.bookingLoading = false;
          state.error = action.error?.message || "Failed to fetch hotels";
          showToast("Internal Server Error","danger");
        },
      }
    ),
    cancelMyBooking: create.asyncThunk(
      async (payload: any) => {
        // console.log("cancelBooking payload", payload);
        try {
          const response = await deleteApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.cancelBookingLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, meta }) => {
          state.cancelBookingLoading = false;
          const { status, message,  data } = payload;
          const { index } = meta?.arg;
          const statusCode = data?.statusCode; 
          if(status == 200 && (statusCode != 5120 || statusCode != 502)){
            showToast(message, "success");
            // state.bookings[index].is_canceled = true;
          }
          else if(statusCode != 5120 || statusCode != 502){
            showToast(message,"danger");
          }
          else{
            showToast(message,"danger");
          }
         
        },
        rejected: (state, action) => {
          state.cancelBookingLoading = false;
          state.error = action.error?.message || "Cancel Booking Failed";
          showToast("Internal Server Error","danger");
        },
      }
    ),
    getMyBooking: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await getFetchApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.viewBookingLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, meta }) => {
          state.viewBookingLoading = false;
          const { status, message,  data } = payload;
          console.log("payload", payload)
          // const { id } = meta?.arg?.payload;
          const id = meta?.arg?.url?.split("?")[1]?.split("=")[2];

          const bookingId: any = String(id);
          if (!state.viewBooking) {
            state.viewBooking = {}; // Initialize if undefined
          }
          if(status == 200 && data && bookingId){
           
            const mark_up_percentage = data?.mark_up_percentage;
            const hotel = data?.hotel;
           /* const booking_details = data?.booking_details;
            const hotel = booking_details?.hotel;
            const updatedData = {
              ...data,
              booking_details: {
                ...booking_details,
                price: {
                  ...booking_details?.price,
                  price_markup: getMarkupPrice(booking_details?.price?.total, mark_up_percentage),
                },
                hotel: {
                  ...hotel,
                  booking_items: hotel?.booking_items.map((item: any) => {
                    const updatedCancellationPolicy = {
                      ...item?.cancellation_policy,
                      // Add price_markup in details array
                      details: item?.cancellation_policy?.details?.map((detail: any) => {
                        if (item.cancellation_policy.amount_type === "value") {
                          const detailMarkup = getMarkupPrice(detail?.flat_fee, mark_up_percentage); // 10% markup on flat_fee
                          return {
                            ...detail,
                            price_markup: detailMarkup, // Add price_markup inside each detail
                          };
                        }
                        return detail; // Keep details unchanged if amount_type is not 'value'
                      }),
                      // Add price_markup in no_show_fee
                      no_show_fee: item?.cancellation_policy?.no_show_fee?.amount_type === "value"
                        ? {
                            ...item?.cancellation_policy?.no_show_fee,
                            price_markup: getMarkupPrice(item?.cancellation_policy?.no_show_fee?.flat_fee, mark_up_percentage), // 10% markup on flat_fee
                          }
                        : item?.cancellation_policy?.no_show_fee, // Keep no_show_fee unchanged if amount_type is not 'value'
                    };
                    return {
                      ...item,
                      price_markup: getMarkupPrice(item?.price, mark_up_percentage),
                      cancellation_policy: updatedCancellationPolicy, // Updated cancellation policy
                    };
                  }),
                },
              }
            };*/
            // console.log("Before Update",data);
            // console.log("After Update",updatedData);
           
            const updatedData = {
              ...data,
              
              hotel: {
                ...hotel,
                booking_items: data?.hotel?.booking_items.map((item: any) => {
                  const updatedCancellationPolicy = {
                    ...item?.cancellation_policy,
                    // Add price_markup in details array
                    details: item?.cancellation_policy?.details?.map((detail: any) => {
                      if (item.cancellation_policy.amount_type === "value") {
                        const detailMarkup = getMarkupPrice(detail?.flat_fee, mark_up_percentage); // 10% markup on flat_fee
                        return {
                          ...detail,
                          price_markup: detailMarkup, // Add price_markup inside each detail
                        };
                      }
                      return detail; // Keep details unchanged if amount_type is not 'value'
                    }),
                    // Add price_markup in no_show_fee
                    no_show_fee: item?.cancellation_policy?.no_show_fee?.amount_type === "value"
                      ? {
                          ...item?.cancellation_policy?.no_show_fee,
                          price_markup: getMarkupPrice(item?.cancellation_policy?.no_show_fee?.flat_fee, mark_up_percentage), // 10% markup on flat_fee
                        }
                      : item?.cancellation_policy?.no_show_fee, // Keep no_show_fee unchanged if amount_type is not 'value'
                  };
            
                  return {
                    ...item,
                    price_markup: getMarkupPrice(item?.price, mark_up_percentage), 
                    cancellation_policy: updatedCancellationPolicy, // Updated cancellation policy
                  };
                }),
              },
              price: {
                ...data?.price,
                price_markup: getMarkupPrice(data?.price?.total, mark_up_percentage),
              },
              cancellation_details: {
                ...data?.cancellation_details,
                refund_amount_markup: getMarkupPrice(data?.cancellation_details?.refund_amount, mark_up_percentage),
                cancellation_charge_markup: getMarkupPrice(data?.cancellation_details?.cancellation_charge?.amount, mark_up_percentage),
              }
            }
            state.viewBooking[bookingId] = updatedData;
            
          }
          else{
            showToast(message,"danger");
          }
         
        },
        rejected: (state, action) => {
          state.viewBookingLoading = false;
          state.error = action.error?.message || "Cancel Booking Failed";
          showToast("Internal Server Error","danger");
        },
      }
    ),
    getMyBookingStatus: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await getFetchApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.viewBookingStatusLoading = true;
          state.error = null;
        },
        fulfilled: (state, { payload, meta }) => {
          state.viewBookingStatusLoading = false;
          state.cancelBookingLoading = false;
          const { status, message,  data } = payload;
          console.log("meta", meta?.arg?.url?.split("?")[1].split("=")[2])
          const id = meta?.arg?.url?.split("?")[1]?.split("=")[2];
          // const { id } = meta?.arg?.payload;
          const bookingId: any = String(id);
          console.log("bookingId", bookingId)
          if (!state.viewBookingStatus) {
            state.viewBookingStatus = {}; // Initialize if undefined
          }
          if(status == 200 && data && bookingId){
           
            const mark_up_percentage = data?.mark_up_percentage;
            const hotel = data?.hotel;
            const updatedData = {
              ...data,
              
              hotel: {
                ...hotel,
                booking_items: data?.hotel?.booking_items.map((item: any) => {
                  const updatedCancellationPolicy = {
                    ...item?.cancellation_policy,
                    // Add price_markup in details array
                    details: item?.cancellation_policy?.details?.map((detail: any) => {
                      if (item.cancellation_policy.amount_type === "value") {
                        const detailMarkup = getMarkupPrice(detail?.flat_fee, mark_up_percentage); // 10% markup on flat_fee
                        return {
                          ...detail,
                          price_markup: detailMarkup, // Add price_markup inside each detail
                        };
                      }
                      return detail; // Keep details unchanged if amount_type is not 'value'
                    }),
                    // Add price_markup in no_show_fee
                    no_show_fee: item?.cancellation_policy?.no_show_fee?.amount_type === "value"
                      ? {
                          ...item?.cancellation_policy?.no_show_fee,
                          price_markup: getMarkupPrice(item?.cancellation_policy?.no_show_fee?.flat_fee, mark_up_percentage), // 10% markup on flat_fee
                        }
                      : item?.cancellation_policy?.no_show_fee, // Keep no_show_fee unchanged if amount_type is not 'value'
                  };
            
                  return {
                    ...item,
                    price_markup: getMarkupPrice(item?.price, mark_up_percentage), 
                    cancellation_policy: updatedCancellationPolicy, // Updated cancellation policy
                  };
                }),
              },
              price: {
                ...data?.price,
                price_markup: getMarkupPrice(data?.price?.total, mark_up_percentage),
              },
              cancellation_details: {
                ...data?.cancellation_details,
                refund_amount_markup: getMarkupPrice(data?.cancellation_details?.refund_amount, mark_up_percentage),
                cancellation_charge_markup: getMarkupPrice(data?.cancellation_details?.cancellation_charge?.amount, mark_up_percentage)
              }
            }
            state.viewBookingStatus[bookingId] = updatedData;
            
          }
          else{
            showToast(message,"danger");
          }
         
        },
        rejected: (state, action) => {
          state.viewBookingStatusLoading = false;
          state.error = action.error?.message || "Cancel Booking Failed";
          showToast("Internal Server Error","danger");
        },
      }
    ),
  }),
  selectors: {
    bookings: (_) => _?.bookings,
    paginationObject: (_) => _?.paginationObject,
    error: (_) => _?.error,
    getBookingLoading: (_) => _?.bookingLoading,
    getCancelBookingLoading: (_) => _?.cancelBookingLoading, 
    getViewBookingLoading: (_) => _?.viewBookingLoading, 
    booking: (_) => _?.viewBooking,
    getViewBookingStatusLoading: (_) => _?.viewBookingStatusLoading, 
    bookingStatus: (_) => _?.viewBookingStatus, 
  },
});

export const { getMyBookings, cancelMyBooking, getMyBooking, getMyBookingStatus } = bookingSlice.actions;
export const { bookings, getBookingLoading, paginationObject, getCancelBookingLoading, getViewBookingLoading, booking, getViewBookingStatusLoading, bookingStatus } = bookingSlice.selectors;
