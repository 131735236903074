"use client";
import { Loader } from "@/components/ui";
import { getlogoutLoading } from "@/lib/features/profile/profileSlice";
import { useAppSelector, useAppDispatch } from "@/lib/hooks";
import { APIProvider, useMap } from "@vis.gl/react-google-maps";

const API_KEY = process.env.NEXT_PUBLIC_PLACE_API_KEY as string;
const MapsProvider = ({ children }: { children: React.ReactNode }) => {
  const logoutLoading = useAppSelector(getlogoutLoading);
  const dispatch = useAppDispatch();

  return (
    <>
      {logoutLoading && <Loader show={logoutLoading} />}
      <APIProvider apiKey={API_KEY} libraries={['marker']}>{children}</APIProvider>
    </>
  );
};

export default MapsProvider;
