import type { Action, ThunkAction } from "@reduxjs/toolkit";
import { combineSlices, configureStore } from "@reduxjs/toolkit";

import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import { createLogger } from 'redux-logger'; 
import { blogSlice } from "./features/blog/blogSlice";
import { findPlaceSlice } from "./features/hero/findPlaceSlice";
import { homeSlice } from "./features/home/homeSlice";
import { hotelSlice } from "./features/hotel/hotelSlice";
import { authSlice } from "./features/auth/authSlice";
import { bookingSlice } from "./features/booking/bookingSlice";
import { profileSlice } from "./features/profile/profileSlice";
import { mapSlice } from "./features/map/mapSlice";
import persistStorage from './storage';
import createTransform from 'redux-persist-transform-compress';
import { createTransform as createJSONTransform } from 'redux-persist';
import createIdbStorage from 'redux-persist-indexeddb-storage';

const compressTransform = createTransform();
// Serialize state into JSON strings
const jsonTransform = createJSONTransform(
  (inboundState) => JSON.stringify(inboundState),
  (outboundState) => {
    try {
      return JSON.parse(outboundState);
    } catch (error) {
      console.error('Error parsing state:', error);
      return {}; // Return an empty state or a default fallback
    }
  }
);
const idbStorage = createIdbStorage({
  name: 'myAppStorage',
  storeName: 'reduxState',
});
// import { quotesApiSlice } from "./features/quotes/quotesApiSlice";

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
// const rootReducer = combineSlices(blogSlice, findPlaceSlice, homeSlice, hotelSlice, authSlice, bookingSlice, profileSlice, mapSlice);
const masterReducer = combineSlices(
  blogSlice,
  findPlaceSlice,
  homeSlice,
  hotelSlice,
  authSlice,
  bookingSlice,
  profileSlice,
  mapSlice
);

const rootReducer = (state: any, action: any) => {
  if (action.type === "profile/logout/fulfilled") {
    // Reset the state to the initial state
    return masterReducer(undefined, action); // `rootReducer` is not called recursively here
  }
  return masterReducer(state, action);
};
// Persist configuration
const persistConfig: any = {
    key: 'root', // key for the root of the persisted state
    storage: idbStorage,
    // storage: persistStorage, // storage method (localStorage in this case)
    transforms: [jsonTransform, compressTransform], // Apply JSON transform first
  };

// Wrap the rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);


// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>;

// `makeStore` encapsulates the store configuration to allow
// creating unique store instances, which is particularly important for
// server-side rendering (SSR) scenarios. In SSR, separate store instances
// are needed for each request to prevent cross-request state pollution.
// Create a logger middleware
const logger = createLogger({
  collapsed: true, // Collapse log entries for readability
  diff: true, // Show the difference between the old and new state
});


export const makeStore = () => {
    const store = configureStore({
      reducer: persistedReducer,
      // Adding the api middleware enables caching, invalidation, polling,
      // and other useful features of `rtk-query`.
      middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })
        // .concat(logger);
        // .concat(quotesApiSlice.middleware);
      },
    });
  
    // Create a persistor
    // const persistor = persistStore(store);

    const persistor = typeof window !== 'undefined' ? persistStore(store) : null;

    return { store, persistor };
  };

// Infer the return type of `makeStore`
export type AppStore = ReturnType<typeof makeStore>['store'];
export type AppPersistor = ReturnType<typeof makeStore>['persistor'];

// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;

