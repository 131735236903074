// mapSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MapState {
  center: { lat: number; lng: number };
  zoom: number;
}

const initialState: MapState = {
  center: { lat: 0, lng: 0 }, // default or initial center coordinates
  zoom: 12, // default zoom level
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapCenter: (state, action: PayloadAction<{ lat: number; lng: number }>) => {
      state.center = action.payload;
    },
    setMapZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
  },
});

export const { setMapCenter, setMapZoom } = mapSlice.actions;
export default mapSlice.reducer;
