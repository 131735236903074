"use client";
import type { AppStore, AppPersistor } from "@/lib/store";
import { makeStore } from "@/lib/store";
import { setupListeners } from "@reduxjs/toolkit/query";
import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// import Aos from "aos";
// import "aos/dist/aos.css";
import {  ToastProvider } from "@/components/ui";
import NavigationService from "@/utils/NavigationService"; // Adjust the path
import { useRouter } from "next/navigation";
import { CustomToastProvider } from "@/app/CustomToastProvider";
interface Props {
  readonly children: ReactNode;
}

const StoreProvider = ({ children }: Props) => {
  const router = useRouter();
  const storeRef = useRef<AppStore | null>(null);
  const persistorRef = useRef<any>(null);
  const [isHydrated, setIsHydrated] = useState(false);

  if (!storeRef.current || !persistorRef.current) {
    // Create the store instance and persistor the first time this renders
    const { store, persistor } = makeStore();
    storeRef.current = store;
    persistorRef.current = persistor;
  }
  useEffect(() => {
    if (storeRef.current != null) {
      const unsubscribe = setupListeners(storeRef.current.dispatch);
      return unsubscribe;
    }
  }, []);

  useEffect(() => {
    const handleHydrated = () => setIsHydrated(true);
    if (persistorRef.current) {
      const unsubscribe = persistorRef.current.subscribe(() => {
        if (persistorRef.current.getState().bootstrapped) {
          handleHydrated();
        }
      });
      return () => unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      require("bootstrap/dist/js/bootstrap");
    }

    // Aos.init({
    //   duration: 100,
    //   once: true,
    // });
  }, []);

  useEffect(() => {
    // Initialize the navigation service with the current router
    if(router){
      NavigationService.initialize(router);
    }
  }, [router]);

  return (
    <>
      <CustomToastProvider>
        <Provider store={storeRef?.current}>
          {persistorRef?.current ? (
            <PersistGate loading={null} persistor={persistorRef.current}>
              {children}
              {/* {isHydrated ? children : <div>Loading Here...</div>} */}
            </PersistGate>
          ) : (
            children // In case persistor is null (SSR case), render children directly
          )}
        </Provider>
      </CustomToastProvider>
    </>
  );
};

export default StoreProvider;
